<template>
  <b-card title="Listagem">
    <listarDocumentosContrato />
  </b-card>
</template>

<script>
// import axios from 'axios'

import {
  BCard,
} from 'bootstrap-vue'
import listarDocumentosContrato from '../../Listagens/DocumentosContratos/ListarDocumentosContratos.vue'

export default {
  components: {
    BCard,
    listarDocumentosContrato,
  },
}
</script>
